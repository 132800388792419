import React, { useEffect } from 'react';
import './App.css';
import { Protected } from './example items/Protected';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { InteractionStatus, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider, useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import i18 from 'translations/i18';
import LoginPage from 'common/LoginPage';
import { logoutSilently, refreshIdToken } from 'config/authConfig';
import { createApiConfiguration } from 'config/apiConfig';
import { UsersApi } from 'skyllful-client-sdk';

type AppProps = {
	pca: PublicClientApplication
};

const App = ({pca} : AppProps) => {
	i18();
	return (
		<MsalProvider instance={pca}>
		<div className="App">
		<header className="App-header">
			<BrowserRouter>
			<Pages />
			</BrowserRouter>
		</header>
		</div>
		</MsalProvider>
	);
};

const Pages = () => {
	return (
		<Switch>
			<Route path="/protected">
				<Protected />
			</Route>
			<Route path="/">
				<LoginPage />
			</Route>
		</Switch>
	);
};

export default App;
