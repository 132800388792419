import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {
  msalInstance, refreshIdToken
} from './config/authConfig';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigEnv } from './config/configEnv';
import { initMultiTenantConfig } from './config/initMultiTenantConfig';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';

(async () => {
  if (ConfigEnv.REACT_APP_IS_MULTI_TENANT) {
    await initMultiTenantConfig();
  } else {
    msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
        refreshIdToken();
      }
    });
  }

  ReactDOM.render((
      <React.StrictMode>
        <App pca={msalInstance} />
      </React.StrictMode>
    ), document.getElementById('root')
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
